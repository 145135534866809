<template>
    <div class="investor-page">
        <div class="container">
           <div class="investors-page-top">
               <div class="investor-page-hero">
                   <div class="investor-page-hero__content">
                       <z-icon
                           name="minilogo"
                           width="66px"
                           height="54px"
                       />
                       <z-caption
                           tag="h2"
                           size="5xl"
                           class="u-top-margin--s u-bottom-margin--m investor-page-hero__title"
                       >
                           <span v-html="textLocales.hero.title "></span>
                       </z-caption>
                       <z-button
                           size="l"
                           tag="a"
                           href="/investors/key-facts/"
                           class="investor-page-hero__button"
                       >
                           {{ textLocales.hero.button }}
                           <template v-slot:right>
                               <z-icon
                                   name="arrow-long"
                                   dir="right"
                                   width="24"
                                   height="24"
                               />
                           </template>
                       </z-button>
                   </div>
                   <div class="investor-page-hero__image">
                       <img src="/images/investors/new/hero.jpg" alt="">
                   </div>
                   <stocks-card
                       class="investor-page-hero__quotes"
                       goal="investor-page-quotes"
                   />
               </div>
               <div class="investor-page-pricing  u-bottom-margin--2xl">
                   <div class="investor-page-pricing__prices">
                       <z-caption
                           tag="h2"
                           decor-left
                           decor-right
                           size="3xl"
                           weight="600"
                           class="u-top-margin--xl u-bottom-margin--s"
                       >
                           {{ textLocales.pricing.priceTitle }}
                       </z-caption>
                       <div class="investor-page-pricing__metals">
                           <z-key-indicator
                               v-for="metal in Object.keys(metals)"
                               :key="metal"
                               :value="metals[metal].value"
                               :name="metals[metal].change"
                               value-text="$"
                               theme="stock"
                               size="xs"
                               reverse-column
                           >
                               <template v-slot:footer>
                                <span>
                                    <z-tooltip icon-hidden placement="bottom" :content="metals[metal].tooltip">
                                        <span>{{ metals[metal].key }}</span>
                                    </z-tooltip>
                                    {{ metals[metal].name }}
                                </span>
                               </template>
                           </z-key-indicator>
                       </div>
                   </div>
                   <div class="investor-page-pricing__analytics">
                       <z-caption
                           tag="p"
                           :decor-left="device === 'desktop'"
                           size="m"
                           weight="600"
                           uppercase
                       >
                           {{ textLocales.pricing.analytics }}
                       </z-caption>
                       <z-button
                           size="l"
                           tag="a"
                           href="/investors/reports-and-results/commodity-research/"
                           :is-circle-icon="false"
                       >
                           {{ textLocales.pricing.button }}
                           <template v-slot:right>
                               <z-icon name="arrow-long" dir="right" width="24" height="24"/>
                           </template>
                       </z-button>
                   </div>
               </div>
           </div>
            <div class="investor-page-results">
                <div class="investor-page-results__results">
                    <div class="investor-page-results__results-inner">
                        <div class="investor-page-results__financial">
                            <z-caption
                                tag="h2"
                                :decor-left="device !== 'mobile'"
                                size="3xl"
                                weight="600"
                                theme="white"
                                class="u-top-margin--xl u-bottom-margin--s"
                            >
                                {{ textLocales.results.financialTitle }} {{ isFullFinYear ? '12M' : '6M' }}
                                {{ financial.name }}
                            </z-caption>
                            <z-filelist class="investor-page-results__filelist">
                                <z-filelist-item
                                    v-if="finPressRelease.detailPageUrl"
                                    :name="textLocales.results.pressRelease"
                                    :href="finPressRelease.detailPageUrl"
                                    :file-size="finPressRelease.size"
                                    icon-name="file/pdf"
                                />
                                <z-filelist-item
                                    :name="textLocales.results.presentation"
                                    :href="finPresentation.link"
                                    :file-size="finPresentation.size"
                                    icon-name="file/pdf"
                                />
                                <z-filelist-item
                                    name="Databook"
                                    :href="finReportDataBook.link"
                                    :size="finReportDataBook.size"
                                    icon-name="file/xlsx"
                                />
                                <z-filelist-item
                                    name="Databook"
                                    :href="finReportDataBook.link"
                                    :size="finReportDataBook.size"
                                    icon-name="file/xlsx"
                                />
                            </z-filelist>
                        </div>
                        <div class="investor-page-results__production">
                            <z-caption
                                tag="h2"
                                :decor-left="device !== 'mobile'"
                                size="3xl"
                                weight="600"
                                theme="white"
                                class="u-top-margin--xl u-bottom-margin--s"
                            >
                                {{ textLocales.results.productionTitle }} {{ prodMonth }}M {{ operation.name }}
                            </z-caption>
                            <z-filelist class="investor-page-results__filelist">
                                <z-filelist-item
                                    v-if="prodPressRelease.detailPageUrl"
                                    :name="textLocales.results.pressRelease"
                                    :href="prodPressRelease.detailPageUrl"
                                    :file-size="prodPressRelease.size"
                                    icon-name="file/pdf"
                                />
                            </z-filelist>
                        </div>
                    </div>
                    <div class="investor-page-results__results-image">
                        <img src="/images/investors/new/results.png" alt="">
                    </div>
                    <z-button
                        kind="bordered-dark"
                        size="m"
                        tag="a"
                        href="/investors/reports-and-results/current-results/"
                        class="u-top-margin--s"
                        :is-circle-icon="false"
                    >
                        {{ textLocales.buttonMore }}
                        <template v-slot:right>
                            <z-icon name="arrow-long" dir="right" width="24" height="24"/>
                        </template>
                    </z-button>
                </div>
                <div class="investor-page-results__indicators u-top-margin--l">
                    <template v-if="['mobile', 'v-tablet'].includes(device)">
                        <div class="investor-page-results__indicator-block" @click="$root.$bus.$emit('open-modal', { id: 'mobileFinancialModal' })">
                            <z-caption
                                tag="h2"
                                size="3xl"
                                weight="600"
                                class="u-top-margin--xl u-bottom-margin--s"
                            >
                                {{ textLocales.indicators.financialTitle }}
                            </z-caption>
                            <div class="investor-page-results__indicators-icon">
                                <z-icon name="arrow-long" dir="right" width="18" height="18"></z-icon>
                            </div>
                        </div>
                        <div class="investor-page-results__indicator-block" @click="$root.$bus.$emit('open-modal', { id: 'mobileProductionModal' })">
                            <z-caption
                                tag="h2"
                                size="3xl"
                                weight="600"
                                class="u-top-margin--xl u-bottom-margin--s"
                            >
                                {{ textLocales.indicators.productionTitle }}
                            </z-caption>
                            <div class="investor-page-results__indicators-icon">
                                <z-icon name="arrow-long" dir="right" width="18" height="18"></z-icon>
                            </div>
                        </div>
                        <z-modal
                            class="investor-page-results__mobile-popup"
                            ref="modal"
                            id="mobileFinancialModal"
                        >
                            <button class="investor-page-results__mobile-popup-back u-bottom-margin--s" @click="$root.$bus.$emit('close-modal', { id: 'mobileFinancialModal' })">
                                <z-icon width="24" height="24" dir="left" name="arrow-long" />
                                <span>{{ textLocales.buttonBack }}</span>
                            </button>
                            <z-caption
                                tag="h2"
                                size="3xl"
                                weight="600"
                                class="u-top-margin--xl u-bottom-margin--s"
                            >
                                {{ textLocales.indicators.financialTitle }}
                            </z-caption>
                            <div class="investor-page-results__table">
                                <div
                                    v-for="item in financialValues"
                                    class="investor-page-results__table-row"
                                >
                                    <span class="investor-page-results__table-title">{{ item.title }}</span>
                                    <span
                                        v-for="row in item.rows"
                                        class="investor-page-results__table-text"
                                    >
                                        <span>{{ row.name }}</span>
                                        <span>{{ row.value }}</span>
                                    </span>
                                </div>
                            </div>
                        </z-modal>
                        <z-modal
                            class="investor-page-results__mobile-popup"
                            ref="modal"
                            id="mobileProductionModal"
                        >
                            <button class="investor-page-results__mobile-popup-back u-bottom-margin--s" @click="$root.$bus.$emit('close-modal', { id: 'mobileProductionModal' })">
                                <z-icon width="24" height="24" dir="left" name="arrow-long" />
                                <span>{{ textLocales.buttonBack }}</span>
                            </button>
                            <z-caption
                                tag="h2"
                                size="3xl"
                                weight="600"
                                class="u-top-margin--xl u-bottom-margin--s"
                            >
                                {{ textLocales.indicators.productionTitle }}
                            </z-caption>
                            <div class="investor-page-results__table">
                                <div
                                    v-for="item in productionValues"
                                    class="investor-page-results__table-row"
                                >
                                    <span class="investor-page-results__table-title">{{ item.title }}</span>
                                    <span
                                        v-for="row in item.rows"
                                        class="investor-page-results__table-text"
                                    >
                                        <span>{{ row.name }}</span>
                                        <span>{{ row.value }}</span>
                                    </span>
                                </div>
                            </div>
                        </z-modal>

                    </template>
                    <template v-else>
                        <z-accordion class="investor-page-results__indicators--desktop">
                            <z-accordion-item id="first">
                                <template v-slot:header>
                                    <div class="investor-page-results__indicators-header">
                                        <z-caption
                                            tag="h2"
                                            decor-left
                                            decor-right
                                            size="3xl"
                                            weight="600"
                                            class="u-top-margin--xl u-bottom-margin--s u-right-margin--l u-left-margin--l"
                                        >
                                            {{ textLocales.indicators.financialTitle }}
                                        </z-caption>
                                        <z-icon
                                            name="round-arrow"
                                            dir="down"
                                            width="36px"
                                            height="36px"
                                            class="u-top-margin--xl u-bottom-margin--s"
                                        />
                                        <z-caption
                                            tag="h2"
                                            decor-left
                                            decor-right
                                            size="3xl"
                                            weight="600"
                                            class="u-top-margin--xl u-bottom-margin--s u-right-margin--l u-left-margin--l"
                                        >
                                            {{ textLocales.indicators.productionTitle }}
                                        </z-caption>
                                    </div>
                                </template>
                                <template v-slot:body>
                                    <div class="investor-page-results__indicators-body">
                                        <z-table
                                            class="investor-page-results__indicators-financial u-bottom-margin--l"
                                            :columns="[textLocales.name, '2021', '2022', '2023']"
                                        >
                                            <z-table-row v-for="item in financialValues">
                                                <z-table-col index="0">{{ item.title }}</z-table-col>
                                                <z-table-col
                                                    v-for="(row, index) in item.rows"
                                                    :index="index + 1"
                                                >
                                                    {{ row.value }}
                                                </z-table-col>
                                            </z-table-row>
                                        </z-table>
                                        <z-table
                                            class="investor-page-results__indicators-financial u-bottom-margin--l"
                                            :columns="[textLocales.name, '2021', '2022', '2023']"
                                        >
                                            <z-table-row v-for="item in productionValues">
                                                <z-table-col index="0">{{ item.title }}</z-table-col>
                                                <z-table-col
                                                    v-for="(row, index) in item.rows"
                                                    :index="index + 1"
                                                >
                                                    {{ row.value }}
                                                </z-table-col>
                                            </z-table-row>
                                        </z-table>
                                    </div>
                                </template>
                            </z-accordion-item>
                        </z-accordion>
                    </template>
                </div>
            </div>
            <div class="investor-page-media u-top-margin--3xl u-bottom-margin--4xl">
                <div class="investor-page-media__col">
                    <z-caption
                        tag="h2"
                        decor-left
                        decor-right
                        size="3xl"
                        weight="600"
                        class="u-top-margin--xl u-bottom-margin--s"
                    >
                        {{ textLocales.media.pressRelease }}
                    </z-caption>
                    <news-list
                        class="investor-page-media__media-news u-bottom-margin--m"
                        theme="aside"
                        :items="news"
                    />
                    <z-button
                        href="/news-and-media/press-releases-and-news/?sources[]=21"
                        tag="a"
                        kind="text"
                        size="l"
                        class="investor-page-media__link"
                    >
                        <template v-slot:left>
                            <z-icon
                                name="arrow-long"
                                dir="right"
                                width="16"
                                height="16"></z-icon>
                        </template>
                        <span v-html="textLocales.media.allNews"></span>
                    </z-button>
                </div>
                <div class="investor-page-media__col">
                    <z-caption
                        tag="h2"
                        decor-left
                        decor-right
                        size="3xl"
                        weight="600"
                        class="u-top-margin--xl u-bottom-margin--s"
                    >
                        {{ textLocales.media.presentation }}
                    </z-caption>
                    <news-list
                        class="investor-page-media__media-news  u-bottom-margin--m"
                        theme="aside"
                        :items="presentationToNews"
                    />
                    <z-button
                        href="/investors/presentations-and-briefings/"
                        tag="a"
                        kind="text"
                        size="l"
                        class="investor-page-media__link"
                    >
                        <template v-slot:left>
                            <z-icon
                                name="arrow-long"
                                dir="right"
                                width="16"
                                height="16"></z-icon>
                        </template>
                        <span v-html="textLocales.media.allPresentations"></span>
                    </z-button>
                </div>
                <div v-if="event" class="investor-page-media__calendar">
                    <z-caption
                        tag="h2"
                        decor-left
                        decor-right
                        size="3xl"
                        weight="600"
                        class="u-top-margin--xl u-bottom-margin--s"
                    >
                        <span v-html="textLocales.media.calendarTitle"></span>
                    </z-caption>
                    <div class="investor-page-media__calendar-card u-bottom-margin--m">
                        <date-block
                            :date="event.date"
                        />
                        <p
                            class="investor-page-media__calendar-name u-top-margin--xl u-bottom-margin--2xl"
                            v-html="event.name"
                        />
                        <z-link :href="event.link">
                            {{ textLocales.media.readMore }}
                        </z-link>
                    </div>
                    <z-button
                        href="/investors/events/"
                        tag="a"
                        kind="text"
                        size="l"
                        class="investor-page-media__link"
                    >
                        <template v-slot:left>
                            <z-icon
                                name="arrow-long"
                                dir="right"
                                width="16"
                                height="16"></z-icon>
                        </template>
                        <span v-html="textLocales.media.allEvents"></span>
                    </z-button>
                </div>
            </div>
            <div class="investor-page-reports">
                <div class="investor-page-reports__col">
                    <annual-report-block
                        :reports-list="['csr', 'ar']"
                        :horizontal="true"
                        col-class="col-default-6 col-tablet-4 col-v-tablet-6 col-mobile-12"
                        class="investor-page-reports__reports"
                    >
                        <template #header>
                            <z-caption
                                decor-left
                                decor-right
                                size="3xl"
                                weight="600"
                                href="/investors/reports-and-results/annual-reports/"
                                class="u-top-margin--xl u-bottom-margin--s"
                            >
                                {{ textLocales.reports.annual }}
                            </z-caption>
                        </template>
                    </annual-report-block>
                </div>
                <div class="investor-page-reports__col">
                    <z-caption
                        decor-left
                        decor-right
                        size="3xl"
                        weight="600"
                        href="/investors/shareholders/interactive-database/"
                        class="investor-page-reports__col-title u-top-margin--xl u-bottom-margin--s"
                    >
                        {{ textLocales.reports.bd }}
                    </z-caption>
                    <p class="investor-page-reports__text u-top-margin--xs u-bottom-margin--xs" v-html="textLocales.reports.bdText"/>
                </div>
                <div class="investor-page-reports__image">
                    <img src="/images/investors/new/bd.png" alt="">
                </div>
            </div>
            <div class="investor-page-strategy">
                <z-caption
                    tag="h2"
                    decor-left
                    decor-right
                    size="3xl"
                    weight="600"
                    class="u-top-margin--xl u-bottom-margin--s"
                >
                    <span v-html="textLocales.strategy.title" />
                </z-caption>
                <z-caption
                    tag="h2"
                    size="5xl"
                    weight="600"
                    class="investor-page-strategy__title u-bottom-margin--2xl-important"
                >
                    <span v-html="textLocales.strategy.heading " />
                </z-caption>
                <div class="investor-page-strategy__list">
                    <bio-item
                        v-for="(element, index) in textLocales.strategy.elements"
                        :key="index"
                        :element="element"
                        goal="investor_page_strategy_block"
                    />
                </div>
            </div>
            <div class="investor-page-diagrams u-top-margin--4xl">
                <div class="investor-page-diagrams__chart">
                    <z-caption
                        tag="p"
                        decor-left
                        decor-right
                        size="m"
                        weight="600"
                        class="u-top-margin--xl no-mb"
                    >
                        {{ textLocales.diagrams.title }}
                    </z-caption>
                    <p class="caption-subtitle" v-html="textLocales.diagrams.subtitle" />
                    <z-chart
                        :theme="['green-labels']"
                        :gradient="true"
                        :options='{
                        data: {
                            columns: [
                                ["Дивиденды", "230.14", "446.10", "607.98", "792.52", "557.20", "1021.22", "1166.22", "915.33"]
                            ],
                            type: "bar",
                            labels: true
                        },
                        size: {
                            height: 410
                        },
                        padding: {
                            top: 0,
                            right: 0,
                            bottom: 20,
                            left: 50
                        },
                        legend: {
                            show: false
                        },
                        axis: {
                            y: {
                                min: 0,
                                max: 1200,
                                tick: {
                                    culling: {
                                        max: 12
                                    },
                                }
                            },
                            x: {
                                type: "category",
                                categories: [
                                    "2015",
                                    "2016",
                                    "2017",
                                    "2018",
                                    "2019",
                                    "2020",
                                    "2021",
                                    "9М 2023"
                                ]
                            }
                        },
                        bar: {
                            width: {
                                ratio: 1,
                                max: 20
                            },
                             hover: false
                        },
                        tooltip: {
                          show: false
                        },
                        point: {
                          focus: {
                            expand: {
                              enabled: false
                            }
                          }
                        },
                    }'
                    ></z-chart>
                    <z-button
                        size="l"
                        kind="secondary"
                        tag="a"
                        href="/investors/shareholders/dividends/"
                        class="u-top-margin--xl"
                        :is-circle-icon="false"
                    >
                        <span v-html="textLocales.diagrams.button"></span>
                        <template v-slot:right>
                            <z-icon
                                name="arrow-long"
                                dir="right"
                                width="18"
                                height="18" />
                        </template>
                    </z-button>
                </div>
                <div class="investor-page-diagrams__diagram">
                    <z-caption
                        tag="p"
                        decor-left
                        decor-right
                        size="m"
                        weight="600"
                        class="u-top-margin--xl no-mb"
                    >
                        {{ textLocales.diagrams.diagramTitle }}
                    </z-caption>
                    <p class="caption-subtitle" v-html="textLocales.diagrams.diagramSubtitle" />
                    <z-chart
                        legend-columns="1"
                        :theme="[
                            'donut-tooltip-center',
                            'disable-legend-interaction'
                        ]"
                        :options='{
                            clipPath: false,
                            size: {
                                height: 300
                            },
                            data: {
                                columns: [
                                    ["INTERROS LIMITED (1)", 37],
                                    ["МКПАО «ЭН+ ГРУП» (1)", 26.39],
                                    [textLocales.diagrams.columns, 36.61]
                                ],
                                order: null,
                                type: "donut"
                            },
                            color: {
                                pattern: [
                                    "#1FBB93",
                                    "#80BBE3",
                                    "#0080C8",
                                    "#F38B00"
                                ]
                            },
                            padding: {
                                top: 24,
                                right: 24,
                                bottom: 24,
                                left: 24
                            },
                            donut: {
                                padAngle: 0,
                                label: {
                                    fullCircle: true,
                                    ratio: 1.6,
                                },
                                width: 35,
                                startingAngle: 0
                            }
                        }'
                        :legend='{
                            position: "left-bottom"
                        }'
                    ></z-chart>
                </div>
            </div>
            <div class="investor-page-contacts">
                <z-caption
                    decor-left
                    decor-right
                    size="3xl"
                    weight="600"
                    href="/contacts/"
                    class="u-top-margin--xl u-bottom-margin--s"
                >
                    {{ textLocales.contacts.title }}
                </z-caption>
                <contact-card
                    photo="/images/contacts/investors/Borovikov.png"
                    :phone="[{
                        text: '+7 (495) 786-83-20',
                        value: '+74957868320'
                    }]"
                    :fax="[{
                        text: '+7 (495) 797-86-13',
                        value: '+74957978613'
                    }]"
                    class="u-bottom-margin--l"
                >
                    <template v-slot:name>
                        {{ textLocales.contacts.name }}
                    </template>
                    <template v-slot:description>
                        <span v-html="textLocales.contacts.position"></span>
                    </template>
                </contact-card>
                <div class="u-flex mp-contacts__wrapper" data-scroll-translate>
                    <div class="mp-contacts__actions">
                        <z-button
                            size="l"
                            kind="primary"
                            @click="$root.$bus.$emit('open-modal', { id: 'investorPageFormModal' })"
                            class="u-right-margin--m"
                        >
                            <span v-html="textLocales.contacts.call"></span>
                            <template v-slot:right>
                                <z-icon
                                    name="arrow-long"
                                    dir="right"
                                    width="18"
                                    height="18"/>
                            </template>
                        </z-button>
                        <z-button
                            size="l"
                            kind="secondary"
                            tag="a"
                            href="/investors/contacts/"
                        >
                            {{ textLocales.contacts.trust }}
                            <template v-slot:right>
                                <z-icon
                                    name="arrow-long"
                                    dir="right"
                                    width="18"
                                    height="18"/>
                            </template>
                        </z-button>
                    </div>
                    <div class="mp-contacts__links-container">
                        <div
                            class="u-flex mp-contacts__pro"
                            v-if="lang === 'ru'">
                            <z-link
                                class="mp-contacts__pro-link"
                                href="https://www.tinkoff.ru/invest/social/profile/Nornickel_Official/"
                            >
                                Пульс
                                <z-icon
                                    name="external"
                                    width="22"
                                    height="22" />
                            </z-link>
                            <z-link
                                href="https://smart-lab.ru/my/Nornickel/"
                                class="mp-contacts__pro-link"
                            >
                                Смартлаб
                                <z-icon
                                    name="external"
                                    width="22"
                                    height="22" />
                            </z-link>
                            <z-link
                                href="https://bcs-express.ru/profit/profile/09f756c7-b0d5-4c4c-b0e9-bb6deae36baf"
                                class="mp-contacts__pro-link"
                            >
                                Профит
                                <z-icon
                                    name="external"
                                    width="22"
                                    height="22" />
                            </z-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <z-modal
            ref="modal"
            class="main-page__contacts-form"
            id="investorPageFormModal">
            <z-caption
                tag="h3"
                size="2xl"
                icon="message">
                <span data-scroll-heading>
                    {{ textLocales.contactsFormTitle }}
                </span>
            </z-caption>
            <main-contacts-form @closeModal="closeModal('investorPageFormModal')" />
        </z-modal>
    </div>
</template>

<script>
import {localize} from '@/utils/i18n'
import {mixinDevice} from '@/utils/mixin'
import moment from 'moment'
import axios from 'axios'

export default {
    name: 'investor-page',
    mixins: [mixinDevice],
    data() {
        return {
            textLocales: {
                hero: {
                    title: localize({
                        en: 'Why invest in&nbsp;Nornickel',
                        ru: 'Основные причины инвестировать в&nbsp;Норникель'
                    }),
                    button: localize({
                        en: 'Learn more',
                        ru: 'Узнать'
                    }),
                    stocks: localize({
                        ru: 'https://nornik-ru.injector.3ebra.net/nornik/ru/',
                        en: 'https://nornik-en.injector.3ebra.net/nornik/en/'
                    }),
                },
                pricing: {
                    priceTitle: localize({
                        en: 'Metal prices',
                        ru: 'Цены на металлы'
                    }),
                    analytics: localize({
                        en: 'Commodity research',
                        ru: 'Аналитика по сырьевым рынкам'
                    }),
                    button: localize({
                        en: 'Learn more',
                        ru: 'Смотреть обзоры'
                    })
                },
                results: {
                    financialTitle: localize({
                        en: 'Financial Results',
                        ru: 'Финансовые результаты',
                    }),
                    productionTitle: localize({
                        en: 'Production Results',
                        ru: 'Производственные результаты',
                    }),
                    pressRelease: localize({
                        ru: 'Пресс-релиз',
                        en: 'Press releases'
                    }),
                    presentation: localize({
                        ru: 'Презентация',
                        en: 'Presentations'
                    })
                },
                indicators: {
                    financialTitle: localize({
                        en: 'Financial Indicators',
                        ru: 'Финансовые показатели',
                    }),
                    productionTitle: localize({
                        en: 'Production Indicators',
                        ru: 'Производственные показатели',
                    }),
                },
                media: {
                    pressRelease: localize({
                        ru: 'Пресс-релизы',
                        en: 'Press release'
                    }),
                    presentation: localize({
                        ru: 'Презентации',
                        en: 'Presentations'
                    }),
                    allNews: localize({
                        en: 'All press releases',
                        ru: 'Все пресс-релизы'
                    }),
                    allPresentations: localize({
                        en: 'All presentations and briefings',
                        ru: 'Презентации и&nbsp;выступления'
                    }),
                    calendarTitle: localize({
                        en: 'IR&nbsp;Calendar',
                        ru: 'Календарь'
                    }),
                    allEvents: localize({
                        en: 'All events',
                        ru: 'Все события'
                    }),
                    readMore: localize({
                        en: 'More details',
                        ru: 'Узнать больше'
                    })
                },
                reports: {
                    annual: localize({
                        en: 'Annual Reports',
                        ru: 'Годовые отчеты'
                    }),
                    bd: localize({
                        en: 'Interactive Database',
                        ru: 'Интерактивная база данных'
                    }),
                    bdText: localize({
                       en: 'Up-to-date information on&nbsp;financial, production, environmental, social and other indicators and results in&nbsp;an&nbsp;easy to&nbsp;analyze format',
                       ru: 'Актуальная информация о&nbsp;финансовых, производственных, экологических, социальных и&nbsp;других показателях и&nbsp;результатах \n' +
                           'в&nbsp;удобном для анализа формате'
                    }),
                },
                strategy: {
                    title: localize({
                        en: 'Approaches and strategies',
                        ru: 'Подходы и&nbsp;стратегии'
                    }),
                    heading: localize({
                        en: 'Development and technological modernization of&nbsp;production',
                        ru: 'Развитие и&nbsp;технологическая модернизация производства'
                    }),
                    elements: [
                        {
                            title: localize({
                                ru: 'Стратегия устойчивого роста',
                                en: 'Sustainable Growth Strategy'
                            }),
                            text: localize({
                                ru: 'Больше «зеленых» металлов для «зеленого» будущего',
                                en: 'More &quot;green&quot; metals for a&nbsp;&quot;green&quot; future'
                            }),
                            img: '/images/investors/new/grow.jpg',
                            link: '/company/strategy/'
                        },
                        {
                            title: localize({
                                ru: 'Экологическая стратегия',
                                en: 'Environmental Strategy'
                            }),
                            text: localize({
                                ru: 'Четкие цели по&nbsp;снижению воздействия на&nbsp;окружающую среду',
                                en: 'Clear goals for reducing environmental impact'
                            }),
                            img: '/images/investors/new/ecology.jpg',
                            link: '/sustainability/'
                        },
                        {
                            title: localize({
                                ru: 'Корпоративное управление',
                                en: 'Corporate Strategy'
                            }),
                            text: localize({
                                ru: 'Баланс интересов всех заинтересованных сторон, участвующих в&nbsp;деятельности компании',
                                en: 'Balance of&nbsp;interests of&nbsp;all stakeholders involved in&nbsp;the company&rsquo;s business'
                            }),
                            img: '/images/investors/new/corporate.jpg',
                            link: '/sustainability/governance/corporate-governance-and-esg/'
                        }
                    ],
                },
                diagrams: {
                  title: localize({
                     en: 'Dividend',
                     ru: 'Размер дивидендов'
                  }),
                  subtitle: localize({
                     en: 'per share, RUR',
                     ru: 'на&nbsp;1&nbsp;акцию/руб'
                  }),
                    button: localize({
                        en: 'Shares and ADRs',
                        ru: 'Акции и&nbsp;АДР'
                    }),
                    diagramTitle: localize({
                        en: 'Shareholding structure',
                        ru: 'Структура акционерного капитала'
                    }),
                    diagramSubtitle: localize({
                        en: '6M&nbsp;2023',
                        ru: 'за&nbsp;1П&nbsp;2023г.'
                    }),
                    columns: localize({
                        en: 'Other shareholding',
                        ru: 'Прочие акционеры'
                    })
                },
                contacts: {
                    title: localize({
                       en: 'Contacts',
                        ru: 'Контакты'
                    }),
                    call: localize({
                        ru: 'Связаться с&nbsp;нами',
                        en: 'Connect with&nbsp;us'
                    }),
                    trust: localize({
                        ru: 'Кoнтакты для инвесторов',
                        en: 'Investors contacts'
                    }),
                    name: localize({
                        ru: 'Боровиков Михаил Александрович',
                        en: 'Borovikov Mikhail'
                    }),
                    position: localize({
                        ru: 'Директор департамента по&nbsp;работе с&nbsp;инвестиционным сообществом',
                        en: 'Head of&nbsp;Investor Relation Department'
                    })
                },
                contactsFormTitle: localize({
                    ru: 'Отправьте ваш вопрос',
                    en: 'Send your question'
                }),
                buttonMore: localize({
                    en: 'More details',
                    ru: 'Подробнее'
                }),
                buttonBack: localize({
                    en: 'Back',
                    ru: 'Назад'
                }),
                name: localize({
                    ru: 'Наименование',
                    en: 'Name'
                })
            },
            metals: {
                Nickel: {
                    value: '',
                    change: '',
                    tooltip: '',
                    key: 'Ni',
                    name: localize({
                        ru: 'Никель',
                        en: 'Nickel'
                    })
                },
                Copper: {
                    value: '',
                    change: '',
                    tooltip: '',
                    key: 'Cu',
                    name: localize({
                        ru: 'Медь',
                        en: 'Copper'
                    })
                },
                Palladium: {
                    value: '',
                    change: '',
                    tooltip: '',
                    key: 'Pd',
                    name: localize({
                        ru: 'Палладий',
                        en: 'Palladium'
                    })
                },
                Platinum: {
                    value: '',
                    change: '',
                    tooltip: '',
                    key: 'Pt',
                    name: localize({
                        ru: 'Платина',
                        en: 'Platinum'
                    })
                }
            },
            lang: this.$root.lang,
            currentLink: '',
            currentFileId: '',
            presentations: [],
            commodityResearch: [],
            financial: null,
            operation: null,
            strategyDay: [],
            news: [],
            event: '',
            financialValues: [
                {
                    title: localize({
                        ru: 'Выручка (Млрд долл. США)',
                        en: 'Revenue'
                    }),
                    rows: [
                        {name: '2021г.', value: '16,9г.'},
                        {name: '2022г.', value: '17,8'},
                        {name: '2023г.', value: '14,4'},
                    ]
                },
                {
                    title: localize({
                        ru: 'EBITDA (Млрд долл. США)',
                        en: 'EBITDA (USDm)'
                    }),
                    rows: [
                        {name: '2021г.', value: '8,7'},
                        {name: '2022г.', value: '10,5'},
                        {name: '2023г.', value: '6,9'},
                    ]
                },
                {
                    title: 'EBITDA margin',
                    rows: [
                        {name: '2021г.', value: '52%'},
                        {name: '2022г.', value: '59%'},
                        {name: '2023г.', value: '48%'},
                    ]
                },
                {
                    title: 'ND/EBITDA',
                    rows: [
                        {name: '2021г.', value: '1,1x'},
                        {name: '2022г.', value: '0,5x'},
                        {name: '2023г.', value: '1,2x'},
                    ]
                },
                {
                    title: localize({
                        ru: 'CAPEX (Млрд долл. США)',
                        en: 'CAPEX (USDm)'
                    }),
                    rows: [
                        {name: '2021г.', value: '4,3'},
                        {name: '2022г.', value: '2,8'},
                        {name: '2023г.', value: '3,0'},
                    ]
                },
            ],
            productionValues: [
                {
                    title: localize({
                        ru: 'Никель (тыс. тонн)',
                        en: 'Nickel (kt)'
                    }),
                    rows: [
                        {name: '2021г.', value: '208,6'},
                        {name: '2022г.', value: '219,0'},
                        {name: '2023г.', value: '193,0'},
                    ]
                },
                {
                    title: localize({
                        ru: 'В том числе \n' +
                        'из собственного сырья',
                        en: 'Including сompany’s \n' +
                        'own metal'
                    }),
                    rows: [
                        {name: '2021г.', value: '208,2'},
                        {name: '2022г.', value: '218,7'},
                        {name: '2023г.', value: '189,9'},
                    ]
                },
                {
                    title: localize({
                        ru: 'Медь (тыс. тонн)',
                        en: 'Copper (kt)'
                    }),
                    rows: [
                        {name: '2021г.', value: '425,4'},
                        {name: '2022г.', value: '433,0'},
                        {name: '2023г.', value: '406,8'},
                    ]
                },
                {
                    title: localize({
                        ru: 'Палладий (тыс. тр. ун.)',
                        en: 'Palladium (koz)'
                    }),
                    rows: [
                        {name: '2021г.', value: '2 692'},
                        {name: '2022г.', value: '2 790'},
                        {name: '2023г.', value: '2 616'},
                    ]
                },
                {
                    title: localize({
                        ru: 'Платина (тыс. тр. ун.)',
                        en: 'Platinum (koz)'
                    }),
                    rows: [
                        {name: '2021г.', value: '664'},
                        {name: '2022г.', value: '651'},
                        {name: '2023г.', value: '664'},
                    ]
                },
            ]
        }
    },
    async created() {
        this.loadMetalsStock()

        if (this.$root.app.components['ir-highlights-page']) {
            this.commodityResearch =
                this.$root.app.components['ir-highlights-page']['commodity-research'] || []
            this.presentations =
                this.$root.app.components['ir-highlights-page']['presentation-and-briefings'] || []
            this.financial =
                this.$root.app.components['ir-highlights-page']['results-and-reports']?.['financial-results']
            this.operation =
                this.$root.app.components['ir-highlights-page']['results-and-reports']?.['operation-results']
            this.news = this.$root.app.components['ir-highlights-page']['news'].map((obj, i) => {
                return {
                    date: obj.activeFrom,
                    link: obj.detailPageUrl,
                    name: obj.name
                }
            })

            let events = this.$root.app.components['ir-highlights-page'].events
            if (events && events.length) {
                events = events.map((obj, i) => {
                    return {
                        date: obj.dateFrom.timestamp,
                        link: obj.detailLink.value,
                        name: obj.name
                    }
                })
                this.event = events[0]
            }

            this.strategyDay = this.$root.app.components['ir-highlights-page']['strategy-day'] || []
        }
    },
    computed: {
        isFullFinYear() {
            return (
                this.financial.fr4Presentation.link ||
                this.financial.fr4PressReleases.detailPageUrl ||
                this.financial.fr4FinancialStatementsRub.link ||
                this.financial.fr4FinancialStatementsUsd.link
            )
        },
        finPresentation() {
            if (this.isFullFinYear) return this.financial.fr4Presentation
            return this.financial.fr2Presentation
        },
        finPressRelease() {
            if (this.isFullFinYear) return this.financial.fr4PressReleases
            return this.financial.fr2PressReleases
        },
        finReportDataBook() {
            if (this.isFullFinYear) return this.financial.ar4DataBook
            return this.financial.ar2DataBook
        },
        prodMonth() {
            if (this.operation.pr4PressRelease.detailPageUrl) return 12
            if (this.operation.pr3PressRelease.detailPageUrl) return 9
            if (this.operation.pr2PressRelease.detailPageUrl) return 6
            if (this.operation.pr1PressRelease.detailPageUrl) return 3
        },
        prodPressRelease() {
            if (this.prodMonth === 12) return this.operation.pr4PressRelease
            if (this.prodMonth === 9) return this.operation.pr3PressRelease
            if (this.prodMonth === 6) return this.operation.pr2PressRelease
            if (this.prodMonth === 3) return this.operation.pr1PressRelease
        },
        presentationToNews() {
            return this.presentations.map(item => {
                return {
                    date: item.activeFrom,
                    link: item.detailPageUrl,
                    name: item.name
                }
            })
        },
    },
    methods: {
        getFormattedString(str, dec) {
            let out = parseFloat(str).toFixed(dec)
            if (this.lang === 'ru') {
                out = out.replace(/\./g, ',')
                out = out.replace(/\B(?=(\d{3})+(?!\d))/g, '&nbsp;')
            } else {
                out = out.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            return out
        },
        loadMetalsStock() {
            axios
                .get(`/local/templates/nornik/json/quotations.json`)
                .then((res) => {
                    let json = res.data
                    for (var key in json) {
                        let value = this.getFormattedString(json[key]['Bid'], 0)
                        let change = this.getFormattedString(json[key]['ChangePercent'], 2)

                        let dayOld = moment(json[key]['Date'], 'YYYY-MM-DD HH:mm:ss')

                        if (dayOld.format('E') === '7') {
                            dayOld.subtract(2, 'days')
                        } else if (dayOld.format('E') === '6') {
                            dayOld.subtract(1, 'days')
                        }

                        let dateFormatted = dayOld.format('DD.MM.YYYY HH:mm')
                        let tooltip = this.lang === 'ru'
                            ? `Данные предоставляются с задержкой минимум на 2 часа по рабочим дням. Данные по состоянию на&nbsp;${dateFormatted}. ${key === 'Nickel' || key === 'Copper' ? 'Фьючерс, 3 мес, LME' : 'Fixing, LBMA'}`
                            : `Market data delayed by 2 hours on business days. Data as of ${dateFormatted}. ${key === 'Nickel' || key === 'Copper' ? 'Futures, 3 months, LME.' : 'Fixing, LBMA'}`


                        this.metals[key].value = value
                        this.metals[key].change = change
                        this.metals[key].tooltip = tooltip
                    }

                    return json
                })
                .catch(function (error) {
                    console.error('Error: ' + error)
                })
        },
        closeModal (id) {
            this.$refs.modal.closeModal({ id })
        },

    }
}
</script>

<style lang="scss">
.investor-page {
    overflow-x: hidden
}
.investors-page-top {
    position: relative;
    &:before {
        content: '';
        position: absolute;
        left: 50%;
        width: 100vw;
        height: calc(100% + 80px);
        background: #F3F4F8;
        z-index: -1;
        pointer-events: none;
        transform: translate(-50%, 0);

        @include breakpoint(v-tablet) {
            display: none;
        }
    }
}
.investor-page-hero {
    position: relative;
    margin-bottom: 15.78vw;
    display: flex;
    justify-content: space-between;
    gap: $token-spacers-s;

    @include breakpoint(desktop) {
        margin-bottom: 10vw;
    }

    @include breakpoint(tablet) {
        margin-bottom: 6vw;
    }

    @include breakpoint(v-tablet) {
        margin-bottom: 0;
        flex-direction: column;
    }

    &__content {
        width: 57.5%;

        @include breakpoint(v-tablet) {
            width: 100%;
        }
    }

    &__title.z-caption {
        @include breakpoint(mobile) {
            font-size: $token-typo-4-xl-mobile-size;
            max-width: 250px;
        }
    }

    &__button.z-button--circled:not(.z-button--kind-text):not(.z-button--rounded) {
        @include breakpoint(mobile) {
            width: fit-content;

            .z-button__icon {
                display: flex;
            }
        }
    }

    &__image {
        position: absolute;
        left: 50%;
        width: 100vw;
        transform: translate(-50%, -8%);
        z-index: -1;
        pointer-events: none;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, #FFF 20.2%, rgba(255, 255, 255, 0) 43.5%),
            linear-gradient(90deg, rgba(243, 244, 248, 0) 81.93%, #F3F4F8 98.95%),
            linear-gradient(180deg, rgba(243, 244, 248, 0) 77.22%, #F3F4F8 92.76%),
            linear-gradient(0deg, rgba(243, 244, 248, 0) 43.92%, #F3F3F3 93.32%);
            pointer-events: none;
        }

        & img {
            max-width: 100%
        }

        @include breakpoint(v-tablet) {
            position: relative;
            margin: -45% 0;
            width: 210vw;
            transform: translate(-50%, 0);
        }

        @include breakpoint(mobile) {
            margin: -45% 0;
            transform: translate(-36%, 0);
        }
    }

    &__quotes {
        width: 32.103%;

        @include breakpoint(v-tablet) {
            width: 100%;
        }
    }
}

.investor-page-pricing {
    display: flex;
    gap: $token-spacers-xs;

    @include breakpoint(v-tablet) {
        flex-direction: column;
    }

    &__prices {
        width: calc(100% - 32.103% - 24px);

        @include breakpoint(v-tablet) {
            padding: 0 $token-spacers-xs;
            width: 100%;
        }
    }

    &__metals {
        display: flex;
        justify-content: space-between;
        gap: $token-spacers-s;

        @include breakpoint(mobile) {
            flex-direction: column;
        }

        & .z-key-indicator {
            padding-right: $token-spacers-s;

            @include breakpoint(tablet) {
                padding-right: 0;
            }
        }
    }

    &__analytics {
        margin-top: $token-spacers-xs;
        padding: $token-spacers-m $token-spacers-m $token-spacers-l;
        width: 32.103%;
        background: #f8f8fa;
        border-radius: 0 40px;

        @include breakpoint(v-tablet) {
            margin-top: $token-spacers-m;
            padding: $token-spacers-s $token-spacers-2-xs $token-spacers-s;
            width: 100%;
        }

        & .z-caption {
            @include breakpoint(mobile) {
                margin-bottom: 16px;
                font-size: 16px;
            }
        }

        & .z-caption--decor-right .z-caption__text {
            @include breakpoint(v-tablet) {
                max-width: 100%;
                padding-right: 0;
            }
            @include breakpoint(mobile) {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
}

.investor-page-results {
    position: relative;
    @include padding-level(top, XS);
    @include padding-level(bottom, 3XL);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        height: 100%;
        background: $token-colors-navy-blue;
        transform: translate(-50%, 0);
        z-index: -1;
        pointer-events: none;
    }

    &__results {
        position: relative;

        &-inner {
            display: flex;
            gap: $token-spacers-2-xl;

            @include breakpoint(v-tablet) {
                flex-direction: column;
                gap: 0;
            }
        }
    }

    &__financial, &__production {
        position: relative;
        max-width: 50%;
        z-index: 1;

        @include breakpoint(v-tablet) {
            max-width: 100%;
        }
    }

    &__filelist {
        @include padding-level(left, XL);
        display: flex;
        flex-wrap: wrap;
        gap: 48px $token-spacers-3-xl;

        @include breakpoint(v-tablet) {
            padding: 0;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
        }

        & .z-filelist-item {
            flex: 1 1;

            & .z-icon path {
                fill: white;
            }

            & .z-link__text {
                color: white;
            }
        }

        & .z-filelist-item + .z-filelist-item {
            margin-top: 0 !important;
        }
    }

    &__results-image {
        position: absolute;
        right: -4%;
        bottom: -$token-spacers-l;
        pointer-events: none;

        @include breakpoint(v-tablet) {
            display: none;
        }
    }

    &__indicators {
        & .z-accordion {
            background: #f3f4f8;
            border-radius: 48px 0;
            overflow: hidden;

            @include breakpoint(v-tablet) {
                background: transparent;
                border-radius: 0;
            }
        }

        & .z-accordion-item {
            @include breakpoint(v-tablet) {
                background: #F3F4F8;
                border-radius: 16px 0;
                overflow: hidden;
                &:not(:last-of-type) {
                    margin-bottom: $token-spacers-s;
                }

                & .z-caption {
                    margin: 0;
                }
            }
        }

        & .z-accordion-item__header {
            background: #f3f4f8;
            &:hover {
                background: #f3f4f8;
            }
        }

        &--desktop {
            & .z-accordion-item__header {
                padding: 0;
            }

            & .z-accordion-item__header-title {
                padding-right: 0;
                width: 100%;
            }

            & .z-accordion-item__header-button {
                display: none;
            }

            & .z-accordion-item__body {
                padding: 0;
            }

            & .z-table {
                @include padding-level(right, L);
                @include padding-level(left, L);
                max-width: calc(50% - 16px);

                &-row {
                    &--head {
                        background: #EBEDF1;
                    }

                    &:not(:last-of-type) {
                        border-bottom: 1px solid #c1c6d2;
                    }
                }
                &-col {
                    &:last-of-type {
                        font-weight: 700;
                        color: $token-colors-navy-blue;
                    }
                }
            }

            & .z-table + .z-table {
                margin-top: 0;
            }
        }

        &-icon {
            min-width: 36px;
            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $token-colors-navy-blue;
            border-radius: 50%;

            & path {
                fill: $token-colors-white;
            }
        }

        & .z-table-row--body:hover {
            background: unset;
        }
    }

    &__indicator-block {
        padding: $token-spacers-s $token-spacers-2-xs;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        border-radius: 16px 0;

        &:not(:last-of-type) {
            margin-bottom: $token-spacers-s;
        }

        & .z-caption {
            margin: 0;
        }
    }

    &__indicators-header {
        @include padding-level(bottom, XS);
        display: flex;
        align-items: center;

        & .z-caption {
            flex: 1 1;
        }
    }

    &__indicators-body {
        display: flex;
        gap: 36px;
    }

    &__mobile-popup {
        &-back {
            display: flex;
            align-items: center;
            gap: 10px;
            border: unset;
            background: none;

            & .z-icon {
                padding: 3px;
                border: 1px solid $token-colors-blue;
                border-radius: 50%;
            }
        }

        & .z-modal__container {
            overflow: scroll;
        }
    }

    &__table-row {
        padding: $token-spacers-2-xs $token-spacers-3-xs;
        border-bottom: 1px solid $token-colors-gray-20;
    }

    &__table-title {
        margin-bottom: $token-spacers-2-xs;
        display: block;
        font-family: $robotoMono;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: -0.16px;
        color: $token-colors-gray-50;
        text-transform: uppercase;
    }

    &__table-text {
        display: flex;
        gap: $token-spacers-3-xs;
        font-family: $robotoMono;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2;
        color: $token-colors-gray-60;
        letter-spacing: -0.16px;

        &:not(:last-of-type) {
            margin-bottom: $token-spacers-3-xs;
        }

        &:last-of-type {
            font-weight: 700;
            color: $token-colors-navy-blue;
        }

        & span {
            width: 50%;
            display: block;
        }
    }

    & .z-modal {
        padding: 0;

        &__close {
            display: none;
        }

        &__container {
            height: 100%;
            border-radius: 0;
        }

        & .z-caption {
            margin: 0 0 8px;
        }
    }
}

.investor-page-media {
    display: flex;
    justify-content: space-between;
    gap: $token-spacers-3-xl;

    @include breakpoint(tablet) {
        margin: 0 0 $token-spacers-m;
        flex-direction: column;
        gap: 0;
    }

    &__col {
        display: flex;
        flex-direction: column;
    }

    &__link.z-button {
        margin-top: auto;
        justify-content: flex-start;
    }

    &__calendar {
        @include padding-level(right, M);
        @include padding-level(bottom, XS);
        @include padding-level(left, M);
        background: #f2f4f8;
        border-radius: 0 40px 0 20px;

        @include breakpoint(tablet) {
            @include margin-level(top, 2XL);
            @include padding-level(right, XS);
            @include padding-level(bottom, S);
            @include padding-level(left, XS);
        }
    }

    &__calendar-card {
        @include padding-level(top, M);
        @include padding-level(right, S);
        @include padding-level(bottom, M);
        @include padding-level(left, S);
        background: #fff;
        border-radius: 0 20px;
    }

    & .date-block__text {
        @include breakpoint(mobile) {
            font-size: 12px;
            font-weight: 600;
            line-height: 140%;
            letter-spacing: 1.2px;
        }
    }

    & .news-list__item-title {
        @include breakpoint(mobile) {
            font-size: 16px;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: 0.16px;
        }
    }
}

.investor-page-reports {
    position: relative;
    @include padding-level(top, 2XL);
    @include padding-level(bottom, 4XL);
    display: flex;
    justify-content: space-between;
    gap: $token-spacers-3-xl;

    @include breakpoint(tablet) {
        padding-bottom: 0;
        flex-direction: column;
        gap: 10px;
    }

    @include breakpoint(v-tablet) {
        padding-top: 0;

    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        height: 100%;
        background: linear-gradient(111deg, #F4F6F9 23.84%, #E6E9EE 100%), #F5F5F5;
        transform: translate(-50%, 0);
        z-index: -1;
        pointer-events: none;
    }

    &__col {
        flex: 1 1;
        max-width: 50%;

        @include breakpoint(tablet) {
            max-width: 100%;
        }
    }

    &__col-title {
        @include breakpoint(mobile) {
            margin-top: 24px;
        }
    }

    & .annual-report-block__header {
        margin-bottom: 72px;

        @include breakpoint(v-tablet) {
            margin-bottom: 44px;
        }
    }

    &__reports {
        @include breakpoint(mobile) {

            .annual-report-block__content-wrapper {
                overflow-x: scroll;
            }

            & .row {
                width: max-content;
            }

            & .col-mobile-12 {
                flex-basis: unset;
                max-width: 100%;
            }
        }
    }

    &__text {
        @include typo-level(2XL);
        font-weight: 600;
        line-height: 1.4;
        letter-spacing: -0.12px;

        @include breakpoint(mobile) {
            font-size: 16px;
            font-weight: 400;
        }
    }

    &__image {
        position: absolute;
        right: -80px;
        bottom: 0;
        max-width: 60%;
        pointer-events: none;

        @include breakpoint(laptop) {
            right: -40px;
        }

        @include breakpoint(tablet) {
            position: relative;
            margin: 0 0 0 auto;
        }

        @include breakpoint(v-tablet) {
            position: relative;
            right: auto;
            margin: -16px -32px 0;
            max-width: 100vw;
        }

        & img {
            margin-bottom: -7px;
            max-width: 100%;
        }
    }
}

.investor-page-strategy {

    & .z-caption--decor-left.z-caption--decor-right .z-caption__text {
       @include breakpoint(mobile) {
           max-width: 100%
       }
    }

    &__title {
        max-width: 1400px;

        &.z-caption {
            @include breakpoint(mobile) {
                font-size: 26px;
            }
        }
    }

    &__list {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        width: 100%;
        gap: 24px;

        @include breakpoint(v-tablet) {
            flex-direction: column;
        }

        @include breakpoint(mobile) {
            gap: 32px;
        }

        .bio-item {
            flex: 1;
            width: 100%;

            @include breakpoint(mobile) {
                text-align: center;
            }
        }

        & .bio-item__title {
            @include typo-level(2XL);
        }
    }
}

.investor-page-diagrams {
    @include padding-level(left, 2XL);
    display: flex;
    gap: $token-spacers-xl;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #F2F4F8;
    border-radius: 0 40px 0;
    overflow: hidden;

    @include breakpoint(laptop) {
        gap: $token-spacers-m;
    }

    @include breakpoint(v-tablet) {
        position: relative;
        padding-left: 0;
        flex-direction: column;
        background: unset;
        border-radius: 0 16px 0;
        overflow: visible;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 100vw;
            height: 100%;
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), #F2F4F8;
            transform: translate(-50%, 0);
            z-index: -1;
            pointer-events: none;
        }
    }

    &__chart {
        @include padding-level(bottom, S);
        display: flex;
        flex-direction: column;
        flex: 1 1;

        & .z-chart {
            width: 100%;
            flex: 1 1;

            @include breakpoint(mobile) {
                &.z-chart--theme-green-labels .bb-text {
                    font-size: 10px;
                }
                & .bb-axis-y .tick tspan {
                    font-size: 10px;
                }

                & .bb-axis-x .tick tspan {
                    font-size: 10px;
                }

                & .bb-axis-x .tick:last-of-type text {
                    transform: translate(0, -5px);
                }
            }

            & .bb-bar._expanded_ {
                fill-opacity: 1;
            }
        }

        & .z-button {
            align-self: flex-start;
        }
    }

    &__diagram {
        @include padding-level(right, S);
        @include padding-level(bottom, XL);
        @include padding-level(left, S);
        background: #F2F4F8;
        min-width: 380px;

        @include breakpoint(v-tablet) {
            position: relative;
            background: unset;
            min-width: 0;
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                width: 100vw;
                height: 100%;
                background: #F2F4F8;
                border-radius: 0 16px 0;
                transform: translate(-50%, 0);
                z-index: -1;
                pointer-events: none;
            }
        }

        & .z-chart {

            @include breakpoint(mobile) {
                overflow: visible;

                & svg {
                    overflow: visible !important;
                }
            }

            & .bb-chart-arc text {
                font-size: 24px;
                font-family: "Proxima Nova W08", Tahoma, sans-serif;
                letter-spacing: -0.129px;

                @include breakpoint(mobile) {
                    font-size: 20px;
                }
            }
            & .z-chart__legend {
                margin-top: 16px;
            }

            & .z-chart__el.bb svg {
                background: url(/images/investors/new/minilogo.svg);
                background-size: 64px 64px;
                background-repeat: no-repeat;
                background-position: 50% 45%;
            }
        }
    }

    & .z-caption--decor-left.z-caption--decor-right .z-caption__text {
        @include breakpoint(mobile) {
            max-width: 100%;
        }
    }
}

.investor-page-contacts {
    @include padding-level(top, L);

    @include breakpoint(v-tablet) {
        padding-top: 0;
    }

    & .mp-contacts__wrapper {
        @include breakpoint(v-tablet) {
            margin-top: 0 !important;
            gap: 0;
        }
    }

    & .contact-card{
        display: flex;
        align-items: center;
        gap: $token-spacers-2-xs;

        @include breakpoint(v-tablet) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    & .contact-card__head{
        margin-right: $token-spacers-xl;
    }

    & .contact-card__info{
        max-width: 317px;
    }

    & .contact-card__row {
        margin: 0;
    }

    & .contact-card__title{
        display: none;
    }

    & .contact-card__link {
        margin-top: 0;
        font-size: 24px !important;

        @include breakpoint(mobile) {
            font-size: 20px !important;
        }
    }
}

.caption-subtitle {
    font-weight: 600;
    font-size: 14px;
    color: $token-colors-navy-blue;
    margin-left: 46px;
    @include margin-level(bottom, S);
    transition: transform $smooth-transition;

    @include breakpoint(mobile) {
        margin-left: 0;
    }
}
.no-mb {
    margin-bottom: 0 !important;
}
</style>
